<template>
  <div>
    <el-drawer
      title="设置权益"
      :visible="visible"
      size="680px"
      @close="handleClose"
      destroy-on-close
    >
      <div class="create-form-container">
        <div class="create-form">
          <el-form :model="form" ref="form" :rules="rules" label-position="top" size="normal">
            <el-form-item label="批次名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="合成开启/结束时间" prop="time_range">
              <el-date-picker
                v-model="form.time_range"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="藏品TXID" prop="txid">
              <el-input v-model="form.txid" placeholder="请输入藏品TXID" size="normal" clearable>
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="onQueryNFT(form.txid)"
                ></el-button>
              </el-input>
              <div class="nft-info" v-if="nftData">
                <div style="margin-right: 20px">
                  藏品名称：{{ nftData.name }} 数量：{{ nftData.total }}
                </div>
                <el-button type="primary" size="mini" @click="handleUse">应用</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-card v-if="albumns.length === 0">
                <el-empty description="暂未关联合成NFT"></el-empty>
              </el-card>

              <el-card v-else>
                <NRowHeader />
                <el-collapse :accordion="false" class="nft-compose-coolapse">
                  <el-collapse-item v-for="(item, index) in albumns" :key="item.id">
                    <!-- content -->
                    <template slot="title">
                      <NRow :nft="item" @change="(val) => handleChange(val, index)" />
                    </template>
                    <div>
                      <NSettings
                        ref="nsettings"
                        :conditions="item.conditions"
                        :rights="item.rights"
                        :description="item.desc"
                      />
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-card>
            </el-form-item>
            <el-form-item style="text-align: right;">
              <el-button type="primary" @click="onConfirm">确定</el-button>
              <el-button @click="handleClose">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getNFTInfoByTxid } from "@/api/nft-attribute.js";
import { addGenerationBatch, getComposeBatchDetail } from "@/api/nft.js";
import NRowHeader from "./nft-row-header.vue";
import NRow from "./nft-row.vue";
import NSettings from "./nft-settings.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    limits: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    NRow,
    NRowHeader,
    NSettings,
  },
  watch: {
    info(newValue, _oldValue) {
      if (newValue.id) {
        this.getAlbumns(newValue.id);
        const start = newValue.start_at_str;
        const end = newValue.end_at_str;
        this.form = {
          name: newValue.name,
          time_range: [start, end],
          txid: newValue.album_txid,
        };
      } else {
        this.form = {};
        this.albumns = [];
        this.nftData = null;
      }
    },
  },

  data() {
    return {
      albumns: [],
      nftData: null,
      form: {},
      rules: {
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
        time_range: [{ required: true, message: "请选择开启结束时间", trigger: "blur" }],
        txid: [{ required: true, message: "请输入藏品TXID", trigger: "blur" }],
      },
    };
  },
  methods: {
    async getAlbumns(id) {
      try {
        const { data } = await getComposeBatchDetail({
          batch_id: id,
        });
        this.albumns = data.data.Albumns || [];

        this.$nextTick(() => {
          console.log(this.$refs.nsettings);
          this.albumns.forEach((item, idx) => {
            const nsettingsRef = this.$refs.nsettings[idx];
            if (nsettingsRef) {
              nsettingsRef.fillData(item);
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleChange(newVal, index) {
      this.albumns = this.albumns.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            enable: newVal,
          };
        } else {
          return item;
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
    async onQueryNFT(txid) {
      if (!txid) {
        this.$message.error("请先输入TXID");
        return;
      }
      const res = await getNFTInfoByTxid(txid);
      if (res.items && res.items[0]) {
        this.nftData = res.items[0];
      } else {
        this.$message.error("请先正确的TXID");
      }
    },

    // 点击应用按钮
    handleUse() {
      console.log(this.nftData);
      this.albumns = [
        {
          txid: this.nftData.id,
          enable: false,
          rights: [],
          desc: "",
          conditions: [],
          icon: this.nftData.pics[0],
          nft_cnt: this.nftData.total,
          nft_name: this.nftData.name,
        },
      ];
    },

    // 点击确定按钮
    onConfirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },

    // 提交新增/编辑
    async submit() {
      const item = this.albumns[0];
      const nsettingsRef = this.$refs.nsettings[0];
      const postData = {
        name: this.form.name,
        start_at_str: this.form.time_range[0],
        end_at_str: this.form.time_range[1],
        type: "forever_realm",
        on_shelf_id: item.txid,
        nft_count: item.nft_cnt,
        rigits: [...nsettingsRef.rightList],
        conditions: nsettingsRef.selectedLimit.map((i) => ({
          name: i.name || i.inputValue,
          count: i.count,
        })),
        desc: nsettingsRef.desc,
        enable: item.enable,
      };

      if (this.info.id) {
        postData["id"] = this.info.id;
      }

      try {
        await addGenerationBatch(postData);
        this.$message.success("设置成功");
        this.$emit("success");
        this.form = {};
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.create-form-container {
  margin: 0 20px 20px;
}
.create-form {
  .title {
    font-weight: bold;
    margin: 16px 0 8px;
  }

  .nft-info {
    font-size: 13px;
    opacity: 0.8;
    margin: 8px 0 0 0;
    display: flex;
    align-items: center;
  }

  .time-info {
    color: #666;
    margin-top: 16px;

    .time-info-item {
      margin: 8px 0;
    }
  }

  .nft-compose-coolapse {
    .el-collapse-item__header {
      height: 68px;
    }
    .el-icon-arrow-right {
      color: #409eff;
    }
  }
}
.create-footer {
  margin: 20px 0;
  text-align: right;
}
</style>
