import config from "@/configs/index";
import axios from "axios";

// NFT属性管理API

// 获取藏品组列表
export function getNFTGroupList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_nft_managent_group_list",
    method: "post",
    data,
  });
}

// 新增藏品组
export function addNFTGroup(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/add_nft_managent_group",
    method: "post",
    data,
  });
}

// 新增藏品组
export function removeNFTGroup(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/delete_nft_managent_group",
    method: "post",
    data,
  });
}

// 查询NFT列表
export function getNFTList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_managent_group_nft_list",
    method: "post",
    data,
  });
}

// 添加nft到藏品组
export function addNFTsToGroup(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/add_nft_to_managent_group",
    method: "post",
    data,
  });
}

// 从藏品组移除单个nft
export function removeNFTFromGroup(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/delete_nft_from_managent_group",
    method: "post",
    data,
  });
}

// 获取权益值列表
export function getRightsList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_nft_equity_value_list",
    method: "post",
    data,
  });
}

// 设置nft权益值
export function setNFTRights(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/set_nft_equity_value",
    method: "post",
    data,
  });
}

// 设置nft权益值
export async function getNFTInfoByTxid(txid) {
  try {
    const params = {
      page: 1,
      page_size: 1,
      txid,
    };
    const { data } = await axios({
      url: config.url + "/phoenix/admin_nft_management/nft_intl_manage_work_list",
      method: "post",
      data: params,
    });
    return data.data;
  } catch (error) {
    return error;
  }
}

// 获取nft过期列表
export function getExpiredList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_nft_expired_at_list",
    method: "post",
    data,
  });
}

// 设置nft过期时间
export function setExpiredTime(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/set_nft_expired_at",
    method: "post",
    data,
  });
}

// 设置作品兑换信息
export function editExchangeInfo(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/edit_exchange_info",
    method: "post",
    data,
  });
}

// 获取兑换条件
export function getExchangeSnapshotTypeList() {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_exchange_snapshot_type_list",
    method: "post",
  });
}

// 获取兑换条件
export function cancelExchangeInfo(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/cancel_exchange_info",
    method: "post",
    data,
  });
}
