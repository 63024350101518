<template>
  <div class="nft-row">
    <el-row :gutter="20" type="flex" align="middle">
      <el-col :span="6" :offset="0">
        <el-image
          :preview-src-list="[nft.icon]"
          :src="`${nft.icon}?x-oss-process=image/resize,w_40`"
          fit="fill"
          :lazy="true"
          style="width: 40px; height: 40px;"
          @click="handlePreview"
        />
      </el-col>
      <el-col :span="9" :offset="0">{{ nft.nft_name }}</el-col>
      <el-col :span="4" :offset="0">{{ nft.nft_cnt }}</el-col>
      <el-col :span="5" :offset="0">
        <el-row :gutter="0">
          <el-col :span="12" :offset="0">
            <div @click="(e) => e.stopPropagation()">
              <el-switch
                :value="nft.enable"
                :active-value="true"
                :inactive-value="false"
                @change="handleChange"
              />
            </div>
          </el-col>
          <el-col :span="12" :offset="0">
            <div
              style="text-align: right; padding-right: 4px; height: 20px; line-height: 20px; color: #409EFF"
            >
              设置
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handlePreview(e) {
      console.log(e);
      e && e.stopPropagation();
    },
    handleChange(e) {
      this.$emit("change", e);
    },
  },
};
</script>

<style lang="less">
.nft-row {
  width: 100%;
  line-height: 1;
  padding: 6px 0;
  color: #555;
}
</style>
