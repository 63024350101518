<template>
  <div class="nft-settings-container">
    <div class="nft-settings-title">
      <p>设置合成条件</p>
      <el-dropdown trigger="click" size="default" @command="handleCommand">
        <el-button icon="el-icon-plus" size="mini" circle></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in limitTypes"
            :key="item.key"
            :disabled="item.disabled"
            :command="item.command"
          >
            {{ item.title }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-for="(limit, index) in selectedLimit" :key="index">
      <LimitRow
        :limit="limit"
        @remove="handleRemoveLimit(index)"
        @changeCount="handleChangeCount"
        @changeValue="handleChangeValue"
        :index="index"
      />
    </div>

    <div class="nft-settings-title" style="margin-top: 12px">
      <p>设置权益</p>
      <el-button icon="el-icon-plus" size="mini" circle @click="handleAddRightItem" />
    </div>
    <div
      class="right-item"
      v-for="(item, index) in rightList"
      :key="`${index}-xx`"
      style="margin-top: 8px"
    >
      <el-input
        :value="item"
        placeholder="请输入"
        size="normal"
        clearable
        @input="(val) => handleInputRight(val, index)"
      />
      <el-button
        icon="el-icon-delete"
        type="danger"
        size="mini"
        circle
        @click="handleRemoveRightItem(index)"
      />
    </div>

    <div class="nft-settings-title" style="margin-top: 12px">
      <p>合成说明</p>
    </div>
    <div>
      <el-input
        v-model="desc"
        type="textarea"
        :autosize="{ minRows: 3 }"
        placeholder="请输入"
        size="normal"
      ></el-input>
    </div>
  </div>
</template>

<script>
import LimitRow from "./limit-row.vue";

export default {
  components: {
    LimitRow,
  },
  props: {
    conditions: {
      type: Array,
      default: () => [],
    },
    rights: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 选中的合成条件
      selectedLimit: [],
      // 权益设置
      rightList: [],
      // 合成说明
      desc: "",
      limitConfig: [
        {
          name: "any_medal",
          desc: "任意勋章",
        },
        {
          name: "any_zodiac",
          desc: "任意生肖",
        },
        {
          name: "any_art_nft",
          desc: "任意艺术家作品",
        },
        {
          name: "any_fuel",
          desc: "任意燃料券",
        },
        {
          name: "",
          desc: "自定义",
        },
      ],
    };
  },
  computed: {
    limitTypes() {
      const selectedLimitName = this.selectedLimit.map((i) => i.name);
      return this.limitConfig.map((limit, idx) => {
        return {
          key: idx,
          title: limit.desc,
          disabled: limit.name !== "" && selectedLimitName.includes(limit.name),
          command: {
            ...limit,
          },
        };
      });
    },
  },
  methods: {
    fillData() {
      this.selectedLimit = this.conditions.map((i) => {
        const findLimit = this.limitConfig.find((limit) => limit.name === i.name);
        return {
          name: i.name,
          desc: findLimit ? findLimit.desc : i.name,
          isSearchName: !findLimit,
          count: i.count,
        };
      });
      this.rightList = [...this.rights];
      this.desc = this.description;
    },
    handleClickAddLimit() {},
    handleRemoveLimit(index) {
      const temp = [...this.selectedLimit];
      temp.splice(index, 1);
      this.selectedLimit = [...temp];
    },
    handleCommand(command) {
      this.selectedLimit = [
        ...this.selectedLimit,
        {
          ...command,
          count: "",
        },
      ];
    },
    handleChangeValue(inputValue, index) {
      console.log(inputValue, index);
      this.selectedLimit = [...this.selectedLimit].map((limit, idx) => {
        if (index === idx) {
          return {
            ...limit,
            inputValue: inputValue,
          };
        }
        return limit;
      });
    },
    handleChangeCount(count, index) {
      this.selectedLimit = [...this.selectedLimit].map((limit, idx) => {
        if (index === idx) {
          return {
            ...limit,
            count: count,
          };
        }
        return limit;
      });
    },

    handleInputRight(val, index) {
      const temp = [...this.rightList];
      temp[index] = val;
      this.rightList = [...temp];
    },
    handleRemoveRightItem(index) {
      const temp = [...this.rightList];
      this.rightList = temp.filter((_item, idx) => idx !== index);
    },
    handleAddRightItem() {
      this.rightList = [...this.rightList, ""];
    },
  },
};
</script>

<style lang="less">
.nft-settings-container {
  background-color: #efefef;
  padding: 16px;

  .nft-settings-title {
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;

    p {
      margin: 0 12px 0 0;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .right-item {
    display: flex;
    align-items: center;

    .el-input {
      margin-right: 8px;
    }
  }
}
</style>
