<template>
  <div style="padding: 20px">
    <div style="text-align: right; margin-bottom: 20px;">
      <el-button type="primary" @click="handleClickAdd">新增</el-button>
    </div>

    <el-table :data="list" border stripe>
      <el-table-column prop="name" label="批次名称" align="center"> </el-table-column>
      <el-table-column prop="albumn_count" label="关联合集" align="center"></el-table-column>
      <el-table-column prop="nft_count" label="关联藏品数量" align="center"></el-table-column>
      <el-table-column prop="enable" label="显示状态" align="center">
        <template slot-scope="scope">
          <el-tag type="success" size="small" v-if="scope.row.enable">显示</el-tag>
          <el-tag type="info" size="small" v-else>隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="start_at" label="开始时间/结束时间" width="320px" align="center">
        <template slot-scope="scope">
          <div style="text-align: center">
            <span>{{ scope.row.start_at_str }}</span>
            <span style="margin: 0 8px">至</span>
            <span>{{ scope.row.end_at_str }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <!-- <el-button size="mini" type="success" @click="handleEdit(scope.row)">
            设置权益
          </el-button> -->
          <el-button type="danger" size="small" @click="handleClickRemove(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="batchFormType === 'add' ? '新增' : '编辑'"
      :visible.sync="batchVisible"
      width="440px"
      @close="batchVisible = false"
    >
      <div>
        <el-form
          :model="batchForm"
          label-position="top"
          ref="batchForm"
          :rules="batchFormRules"
          size="normal"
          :disabled="batchFormDisabled"
        >
          <el-form-item label="批次名称" prop="name">
            <el-input
              style="width: 400px"
              v-model="batchForm.name"
              placeholder="请输入批次名称，名称不重复"
            ></el-input>
          </el-form-item>
          <el-form-item label="合成开启/结束时间" prop="time_range">
            <el-date-picker
              v-model="batchForm.time_range"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="batchVisible = false">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm">确定</el-button>
      </span>
    </el-dialog>

    <create
      :info="batchInfo"
      :visible="visible"
      @close="handleCloseCreate"
      @success="handleSetSuccess"
      :batchList="batchList"
    />
  </div>
</template>

<script>
import {
  addComposeBatch,
  deleteComposeBatch,
  getComposeBatches,
  updateComposeBatch,
} from "@/api/nft.js";
import dayjs from "dayjs";
import create from "./create.vue";

export default {
  components: {
    create,
  },
  data() {
    return {
      visible: false,
      list: [],
      batchList: [
        {
          batch_number: 1656120259,
          created_at: 1656120259,
          nft_album_count: 3,
          nft_count: 9,
          on_sell_at: 1656121810,
          priority_purchase_at: 1656121319,
          reserve_purchase_end: 1656121020,
          reserve_purchase_start: 1656120600,
          title: "预约购001",
          updated_at: 1656120436,
        },
        {
          batch_number: 1656077706,
          created_at: 1656077706,
          nft_album_count: 1,
          nft_count: 2,
          on_sell_at: 1656079253,
          priority_purchase_at: 1656078884,
          reserve_purchase_end: 1656078420,
          reserve_purchase_start: 1656077880,
          title: "测试8881",
          updated_at: 1656077706,
        },
      ],

      batchVisible: false,
      batchFormType: "add",
      batchForm: {},
      batchFormDisabled: false,
      batchFormRules: {
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
        time_range: [{ required: true, message: "请选择开启结束时间", trigger: "blur" }],
      },
      updateId: "",

      // 编辑相关
      batchInfo: {},
      albumns: [],
    };
  },
  filters: {
    formateDate: function(value) {
      if (!value || +value === 0) {
        return "-";
      }
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getComposeBatches();
        this.list = data.data.batches || [];
      } catch (error) {}
    },

    handleCloseCreate() {
      this.visible = false;
    },
    handleClickAdd() {
      this.batchInfo = {};
      this.visible = true;
    },
    closebatchForm() {
      this.batchVisible = false;
      this.batchForm = {};
    },
    handleUpdate(item) {
      this.batchVisible = true;
      this.batchFormType = "update";
      this.updateId = item.id;
      const start = item.start_at_str; // new Date(item.start_at_str).getTime();
      const end = item.end_at_str; // new Date(item.end_at_str).getTime();
      this.batchFormDisabled = Date.now() > new Date(end).getTime();
      this.batchForm = {
        name: item.name,
        time_range: [start, end],
      };
    },
    handleClickConfirm() {
      if (this.batchFormType === "add") {
        this.handleAddBatch();
      } else {
        this.handleUpdateBatch();
      }
    },
    handleUpdateBatch() {
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          this.updateBatch();
        } else {
          return false;
        }
      });
    },
    handleAddBatch() {
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          this.addBatch();
        } else {
          return false;
        }
      });
    },
    async addBatch() {
      const [start, end] = this.batchForm.time_range;
      const params = {
        name: this.batchForm.name,
        start_at_str: start, // dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
        end_at_str: end, // dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
      };
      try {
        await addComposeBatch(params);
        this.$message.success("新增成功");
        this.closebatchForm();
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },

    async updateBatch() {
      const [start, end] = this.batchForm.time_range;
      const params = {
        id: this.updateId,
        name: this.batchForm.name,
        start_at_str: start, // dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
        end_at_str: end, // dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
      };
      try {
        await updateComposeBatch(params);
        this.$message.success("编辑成功");
        this.closebatchForm();
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
    handleChangeStatus() {
      this.$message("TODO");
    },

    handleEdit(item) {
      this.batchInfo = { ...item };
      this.visible = true;
    },
    handleSetSuccess() {
      this.visible = false;
      this.fetchData();
    },
    handleDetail() {
      this.$message("TODO");
    },

    handleClickRemove(item) {
      const now = Date.now();
      const start = new Date(item.start_at_str).getTime();
      const end = new Date(item.end_at_str).getTime();
      if (now >= start && now < end) {
        this.$message.warning("合成开启中，请结束后再进行删除操作");
        return;
      }

      this.$confirm("此操作将删除该批次, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleRemove(item);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async handleRemove(item) {
      try {
        await deleteComposeBatch({
          id: item.id,
        });
        this.$message.success("删除成功");
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
