<template>
  <div style="margin: 10px 0;">
    <div style="display: flex; align-items: center; align-items: center;">
      <el-input
        v-if="limit.name !== ''"
        :value="limit.desc"
        placeholder="请输入"
        size="normal"
        @change="handleChangeValue"
        style="margin-right: 8px; flex: 3"
        disabled
      />
      <el-input
        v-else
        v-model="value"
        placeholder="请输入"
        size="normal"
        @change="handleChangeValue"
        @input="handleInputValue"
        style="margin-right: 8px; flex: 3"
      />
      <el-input
        :value="limit.count"
        placeholder="请输入"
        @input="handleChangeCount"
        style="margin-right: 8px; flex: 1"
        type="number"
        class="no-spin-button"
      >
        <div style="padding: 0 10px; height: 40px; line-height: 40px;" slot="suffix">个</div>
      </el-input>
      <el-button
        type="danger"
        icon="el-icon-delete"
        circle
        size="mini"
        @click="handleRemove"
      ></el-button>
    </div>
    <div v-if="searchName !== ''" style="padding: 0 8px;"><span>NFT: </span>{{ searchName }}</div>
    <div v-if="searchError !== ''" style="padding: 0 8px; color: red;">{{ searchError }}</div>
  </div>
</template>

<script>
import { getNFTNameByTxid } from "@/api/nft.js";

export default {
  props: {
    limit: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: "",
      count: "",
      searchName: "",
      searchError: "",
    };
  },
  mounted() {
    if (this.limit && this.limit.isSearchName) {
      this.searchNFTName(this.limit.name);
    }
  },
  methods: {
    async searchNFTName(txid) {
      try {
        const { data } = await getNFTNameByTxid({
          txid,
        });
        if (data && data.data && data.data.name) {
          this.searchName = data.data.name;
          this.searchError = "";
          this.$emit("changeValue", txid, this.index);
        } else {
          this.searchName = "";
          this.searchError = "badge code有误";
        }
      } catch (error) {
        console.log(error);
        this.searchName = "";
        this.searchError = "badge code有误";
      }
    },
    handleChangeValue(val) {
      this.searchNFTName(val);
    },
    handleInputValue() {
      this.searchName = "";
      this.searchError = "";
    },
    handleChangeCount(val) {
      console.log(val);
      this.$emit("changeCount", val, this.index);
    },
    handleRemove() {
      this.$emit("remove");
    },
  },
};
</script>
